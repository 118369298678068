import RadioButton from "components/fields/RadioButton";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class RadioGroup extends Component {
  constructor(props) {
    super(props);

    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  handleCheckChange(value, e) {
    const currentChecked = value === this.props.value;

    // 連打した場合、チェック状態を解除できるように、nullを渡す
    this.props.onChange(
      this.props.isClearable && currentChecked ? null : value,
    );
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.radios.map((radioProps, idx) => {
          const isChecked = radioProps.value === this.props.value;

          return (
            <RadioButton
              key={`${this.props.key}-${idx}`}
              isChecked={isChecked}
              label={radioProps.label}
              value={radioProps.value}
              onChange={this.handleCheckChange}
              isDisabled={this.props.isDisabled}
              description={radioProps.description}
            />
          );
        })}
      </div>
    );
  }
}

RadioGroup.defaultProps = {
  isClearable: false,
  isDisabled: false,
  key: "",
};

RadioGroup.propTypes = {
  isClearable: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  key: PropTypes.string,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      description: PropTypes.string,
    }),
  ),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};
