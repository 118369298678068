import i18next from "i18n";
import camelCase from "lodash/camelCase";
import escapeRegExp from "lodash/escapeRegExp";
import get from "lodash/get";
import has from "lodash/has";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import isPlainObject from "lodash/isPlainObject";
import keys from "lodash/keys";
import reduce from "lodash/reduce";
import set from "lodash/set";
import snakeCase from "lodash/snakeCase";

export * from "./locale";

/** @todo object型は使わないように */
// eslint-disable-next-line @typescript-eslint/ban-types
export type RequestObject = object | any[]; // eslint-disable-line @typescript-eslint/no-explicit-any

/** S3アップロード時のエラーレスポンス */
interface S3ErrorResponse extends Error {
  response: {
    data: string;
    headers: {
      "content-type": string;
    };
  };
}

interface Route {
  /** @todo 必須かオプショナルか確認する */
  onewayDistance?: number;
}

interface User {
  departments: { isApprover: boolean }[];
}

/**
 * 与えられた経路の距離を計算する
 * @param route 交通費経費の経路
 * @param isRoundTrip 往復であればtrue
 * @returns 経路全体の距離（路線データまたは距離データがない場合はnull）
 */
export function calcDistance(
  route: Route | null,
  isRoundTrip: boolean,
): number | null {
  if (route && !isNil(route.onewayDistance)) {
    return isRoundTrip ? route.onewayDistance * 2 : route.onewayDistance;
  }
  return null;
}

export function camelizeKeys<T extends RequestObject>(obj: T): T {
  // 引数がオブジェクトだった場合はkeyをcalmelizeする
  if (isPlainObject(obj)) {
    return reduce(
      keys(obj),
      (result, key) => {
        let k = key;
        if (/^\w*$/.test(key)) {
          k = camelCase(key);
        }
        const value = obj[key];
        const v =
          isArray(value) || isPlainObject(value) ? camelizeKeys(value) : value;
        return { ...result, [k]: v };
      },
      {},
    ) as T;
  }

  // Arrayなら各要素をさらにcamelizeKeysにかける
  if (isArray(obj)) {
    return obj.map((value) => {
      return isArray(value) || isPlainObject(value)
        ? camelizeKeys(value)
        : value;
    }) as T;
  }

  // オブジェクトでも配列でもなければそのまま返す
  return obj;
}

export function getIncrementalMatcher(
  value: string | null,
  option = "",
): RegExp {
  if (isNil(value) || value.length === 0) {
    return new RegExp(".*", option);
  }

  let exp = ".*";
  for (let i = 0; value[i]; i++) {
    exp += `${escapeRegExp(value[i])}.*`;
  }

  return new RegExp(exp, option);
}

/** @todo object型は使わないように */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getMessageFromResponse(
  response: object,
  defaultMessage?: string | null,
): string {
  return (
    get(response, "responseJSON.message") ||
    defaultMessage ||
    i18next.t("commons.errors.communicationError")
  );
}

/**
 * @param pattern urlのパターン文字列。:idと書かれた場所にマッチした値を返す e.g. /xxx/yyy/:id/zzz
 */
export function getUUIDFromUrl(pattern: string): string | null {
  const path = window.location.pathname;
  const reg = new RegExp(pattern.replace(":id", "([0-9a-z-]+)"));
  const result = path.match(reg);

  if (result && result.length > 1 && result[1].length === 36) {
    return result[1];
  }
  return null;
}

export function initIntlCurrencyObj(
  currency: string,
  locale = "ja-JP",
): Intl.NumberFormat {
  return new Intl.NumberFormat(locale, { style: "currency", currency });
}

export function isApprover(user: User): boolean {
  return user.departments.some((d) => d.isApprover === true);
}

export function isCorporatePlan(): boolean {
  return userPreferences?.mainPlan === "corporate";
}

export function isEdge(
  userAgent = window.navigator.userAgent.toLowerCase(),
): boolean {
  return userAgent.indexOf("edge") !== -1;
}

export function isWindows(
  userAgent = window.navigator.userAgent.toLowerCase(),
): boolean {
  return userAgent.indexOf("windows") !== -1;
}

export function isSafari(
  userAgent = window.navigator.userAgent.toLowerCase(),
): boolean {
  return (
    userAgent.indexOf("chrome") === -1 && userAgent.indexOf("safari") !== -1
  );
}

export function getReportDisplayName(): string {
  if (isCorporatePlan()) {
    return i18next.t("reports.properties.application");
  }
  return i18next.t("transactions.inputs.personalReport");
}

export function getReportTitleLabel(): string {
  if (isCorporatePlan()) {
    return i18next.t("transactions.properties.reportTitle");
  }
  return i18next.t("transactions.properties.personalReportTitle");
}

export function isSignedIn(): boolean {
  return !!document.cookie;
}

export function isUnknownCategory(
  transactionInputBy: string,
  transactionStatus: string,
): boolean {
  return (
    transactionInputBy === "worker" &&
    transactionStatus !== "waiting_for_worker"
  );
}

/** 既存のデータとフェッチしてきたデータをマージしたオブジェクトリストを作成する */
export function mergeFetchingData<T>(
  oldList: T[],
  fetchData: T[],
  count: number,
  offset: number,
): T[] {
  const newList: T[] = [...new Array(count)];

  // 既存のデータをリストへ詰める
  oldList.some((item, idx) => {
    if (idx >= newList.length) {
      return true;
    }
    newList[idx] = item;
    return false;
  });

  // 新規のデータをリストへ詰める
  fetchData.forEach((item, idx) => {
    newList[+offset + idx] = item;
  });

  return newList;
}

export function snakecaseKeys<T extends RequestObject>(obj: T): T {
  if (isArray(obj)) {
    return obj.map((value) => {
      return isArray(value) || isPlainObject(value)
        ? snakecaseKeys(value)
        : value;
    }) as T;
  }

  if (isPlainObject(obj)) {
    return reduce(
      keys(obj),
      (result, key) => {
        // lodashのsnakeCaseはs3Keyやoffice365のような文字列を与えた場合にs_3_key, office_365と変換されてしまうため、数字の前に_が入らない形で変換されるようにする
        const k = snakeCase(key).replace(/_(\d)/g, "$1");
        const value = obj[key];
        const v =
          isArray(value) || isPlainObject(value) ? snakecaseKeys(value) : value;
        return set(result, k, v);
      },
      {},
    ) as T;
  }

  return obj;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve();
      } catch (e) {
        reject(e);
      }
    }, ms);
  });
}

interface SortableObject {
  id: string;
  name: string;
  parentId: string | null;
  sort: number;
}

function getAncestorArray<T extends SortableObject>(
  object: T,
  array: T[],
  nestedArray: T[] = [],
): T[] {
  const nextNestedArray = [object].concat(nestedArray);
  const parent = array.find((c) => c.id === object.parentId);
  if (isNil(parent)) {
    return nextNestedArray;
  }
  return getAncestorArray(parent, array, nextNestedArray);
}

export function sortNestedArrayByFamily<T extends SortableObject>(
  array: T[],
): T[] {
  const s = array
    .map((c) => getAncestorArray<T>(c, array))
    .sort((a, b) => {
      let i = 0;

      while (i < Math.min(a.length, b.length)) {
        const diff = a[i].sort - b[i].sort;
        if (diff < 0) {
          return -1;
        }
        if (diff > 0) {
          return 1;
        }
        if (diff === 0) {
          if (a[i].id !== b[i].id) {
            if (a[i].name < b[i].name) {
              return -1;
            }
            return 1;
          }
        }
        i++;
      }

      if (a.length < b.length) {
        return -1;
      }
      if (a.length > b.length) {
        return 1;
      }

      return 0;
    });

  return s.map((familyArray) => familyArray[familyArray.length - 1]);
}

function calcYIQ(r: number, g: number, b: number): number {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

export function yiqFromRGB(rgb: string): number {
  const rgbMatch = rgb.match(/#([0-9a-fA-F]{3,6})/i);
  if (!rgbMatch) {
    return -1;
  }

  const rgbStr = rgbMatch[1];
  const len = rgbStr.length === 3 ? 1 : 2; // 3桁の時と、6桁の時を区別 e.g. #CCC || #CCCCCC

  const r = parseInt(rgbStr.substr(0, len), 16);
  const g = parseInt(rgbStr.substr(len * 1, len), 16);
  const b = parseInt(rgbStr.substr(len * 2, len), 16);

  if (len === 1) {
    return calcYIQ(16 * r + r, 16 * g + g, 16 * b + b);
  }

  return calcYIQ(r, g, b);
}

/** 経費作成・申請が可能なログイン者か(本人または[経費作成・申請]権限のある代理人)、監査権限がないこと */
export function isExpenseCreatableAndRequestableMember(): boolean {
  if (userPreferences.isAuditor) {
    return false;
  }

  return isNil(userPreferences.agent) || userPreferences.agent?.requestable;
}

/** 2つのオブジェクトの型が一致しているかを返します。
 *  @param crrentObj: 現在のオブジェクト
 *  @param referenceObj: あるべき型のオブジェクト
 */
// T型のvalueは型指定無いのでanyを許可。
/* eslint-disable @typescript-eslint/no-explicit-any */
export const isSameType = <T extends { [key: string]: any }>(
  crrentObj: { [key: string]: any },
  referenceObj: T,
): boolean => {
  // eslint-enable @typescript-eslint/no-explicit-any */
  const refkeys = Object.keys(referenceObj);

  return refkeys.every((refKey) => {
    /** あるべきkeyが存在しない場合false */
    if (crrentObj[refKey] === undefined) return false;
    /** 型が異なるkeyがある場合false */
    if (typeof crrentObj[refKey] !== typeof referenceObj[refKey]) return false;
    /** オブジェクトの場合、その中身の型も比較 */
    if (typeof crrentObj[refKey] === "object")
      return isSameType(crrentObj[refKey], referenceObj[refKey]);

    return true;
  });
};

/** 通常のレスポンスエラー `{ responseJSON: { message: '***' } }` の形式であることを検証する */
export function isValidationErrorResponse(
  e: unknown,
): e is { responseJSON: { message: string } } {
  return (
    typeof e === "object" &&
    e !== null &&
    !(e instanceof Error) &&
    has(e, "responseJSON.message")
  );
}

/** S3アップロード時のエラーレスポンスであることを検証する */
export function isS3ErrorResponse(e: unknown): e is S3ErrorResponse {
  return (
    e instanceof Error &&
    has(e, "response.data") &&
    typeof (e as S3ErrorResponse).response.data === "string" &&
    has(e, "response.headers.content-type") &&
    (e as S3ErrorResponse).response.headers["content-type"] ===
      "application/xml"
  );
}

/**
 * Bugsnagへのnotifyを行う。ただし、通常のバリデーションエラーである場合は通知しない。
 * 渡されたエラーがError型でない場合、metaData.rawとしてそのオブジェクトを渡す。
 */
export function notifyUnexpectedErrorToBugsnag(
  e: unknown,
  options: { bugsnagClient?: typeof Bugsnag } = {},
): void {
  const { bugsnagClient = Bugsnag } = options;

  if (!bugsnagClient) return;

  // バリデーションエラーの場合は通知しない
  if (isValidationErrorResponse(e)) return;

  if (isS3ErrorResponse(e)) {
    const wrappedError = Object.create(e); // 元のエラーオブジェクトをプロトタイプに持つ新しいオブジェクトを作成（元のオブジェクトを上書きしないように）
    wrappedError.name = "S3UploadError"; // 新しいエラーオブジェクトのnameを変更
    bugsnagClient.notify(wrappedError, (event) => {
      event.addMetadata("response", { data: e.response.data });
    });
  } else if (e instanceof Error) {
    bugsnagClient.notify(e);
  } else {
    const name = has(e, "name") ? (e as { name: string }).name : undefined;
    const responseJSON = has(e, "responseJSON")
      ? (e as { responseJSON: unknown }).responseJSON
      : undefined;
    const body = responseJSON
      ? JSON.stringify(responseJSON)
      : JSON.stringify(e);

    bugsnagClient.notify(
      {
        name: name || "UnexpectedError (not a ValidationError)",
        message: `${body.slice(0, 100)}... (see RAW tab for details)`,
      },
      (event) => {
        event.addMetadata("raw", e);
      },
    );
  }
}

export function textToLink(text: string): string {
  const urlRegex =
    /((?:^|[\s\u3000]))(https?:\/\/[a-zA-Z0-9.-]+(?:\/[^\s\u3000<>#"]*)?(?:\?[^\s\u3000<>#"]+)?(?:#[^\s\u3000<>#"]+)?)((?=$|[\s\u3000]))/g;

  // XSSエスケープ
  const escapeHtml = (unsafe: string): string => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  };

  // 安全なURLのみリンク化
  const isSafeUrl = (url: string): boolean => {
    return /^https?:\/\//.test(url);
  };

  const urlDecode = (url: string): string => {
    try {
      return decodeURIComponent(url);
    } catch (e) {
      return url;
    }
  };

  const linkedText = escapeHtml(text).replace(
    urlRegex,
    (match, prefix, url, surfix) => {
      const decodedUrl = urlDecode(url);
      const safeUrl = encodeURI(decodedUrl);

      if (!isSafeUrl(safeUrl)) {
        return escapeHtml(match); // 危険なURLはプレーンテキストとして表示
      }

      return `${prefix}<a target="_blank" href="${safeUrl}" class="text-blue-400">${escapeHtml(
        url,
      )}</a>${surfix}`;
    },
  );

  return linkedText;
}

export function resetValidateMessage(className: string): void {
  const targetElement = document.querySelector(className);
  if (targetElement) {
    const formErrorElement = targetElement.parentElement?.querySelector(
      ".formError",
    ) as HTMLDivElement;

    if (formErrorElement) {
      formErrorElement.style.display = "none";
    }
  }
}

export function getDatePickerClassName(date: Date): string {
  const day = date.getDay();
  switch (day) {
    case 0:
      return "datepicker__day--sunday";
    case 6:
      return "datepicker__day--saturday";
    default:
      return "";
  }
}

interface beforeShowDayConfig {
  enable: boolean;
  classes: string;
}

export function getDatePickerDayConfig(date: Date): beforeShowDayConfig {
  const day = date.getDay();
  switch (day) {
    case 0:
      return { enable: true, classes: "datepicker__day--sunday" };
    case 6:
      return { enable: true, classes: "datepicker__day--saturday" };
    default:
      return { enable: true, classes: "" };
  }
}
